import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Notify } from '../model/notify';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { environment } from 'src/environments/environment';
import { Payment } from '../model/payment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(environment.authUser+':'+environment.authPass)

  }),
};

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Payment');
  }


  // URL to web API
  private restBaseUrl = 'processor/notify';
  sendingNotify(payload: Notify, isGift:boolean): Observable<Notify> {
    console.log('Notify Service send')
    let urlBaseFinal = isGift ? 'processor-link/notify':this.restBaseUrl
    console.log("URL notify---> "+urlBaseFinal)

    return this.http
      .post<Notify>(urlBaseFinal, payload, httpOptions)
      .pipe(catchError(this.handleError('sendingNotify', payload)));
  }

  payment(payment: any): Observable<any> {
    var clone = Object.assign({}, payment);
    delete clone['name']
    delete clone['phone']
    delete clone['street']
    delete clone['city']
    delete clone['zipcode']
    delete clone['town']
    delete clone['ip']
    delete clone['expiry_month']
    delete clone['expiry_year']
    delete clone['isGift']
    delete clone['token']
    delete clone['number']

    let payload_final = {data: clone}
    let restBaseUrl =`${environment.urlPaymentProccessor}processor/payment`
    //let payload = {data:clone}
    console.log(payload_final)
    return this.http
    .post<Payment>(restBaseUrl, payload_final, httpOptions)
    .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }
}
