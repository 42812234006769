import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Payment } from 'src/app/model/payment';
import { NotifyService } from 'src/app/services/notify.service';

declare var redirectPage: any;


@Component({
  selector: 'app-openpay',
  templateUrl: './openpay.component.html',
  styleUrls: ['./openpay.component.css']
})
export class OpenpayComponent implements OnInit {
  formGroup: FormGroup;

  numberReguex: RegExp = /[0-9]$/
  submitted:boolean;

  payment:Payment=new Payment();
  maxlength:number=4
  message: string = '';
  isAmex: boolean = false;
  withError = false;


  constructor(private formBuilder: FormBuilder, private notifyService: NotifyService,
    ) {
    this.payment=new Payment();
    this.formGroup = this.formBuilder.group({
      'cvv': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    });

  }

  ngOnInit(): void {
  }

  verificaCVV() {
    this.message = '';
    this.withError = false;
    
    let cardNumber = document.getElementById('number_span')?.innerHTML;
    let firstDigit = cardNumber?.substring(0, 1);
    console.log(firstDigit)
    if (firstDigit == '3') {
      this.isAmex = true;
      this.maxlength = 4;
    }
  }

  onSubmit() {
    let cvvStr: String = new String(this.formGroup.controls['cvv'].value);
    console.log(cvvStr)
    if (this.isAmex && cvvStr.length != 4) {
      this.message = '<ul><li>Por favor ingrese 4 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (!this.isAmex && cvvStr.length != 3) {
      this.message = '<ul><li>Por favor ingrese 3 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (this.withError == false) {
      this.payment.cvv = new String(this.formGroup.controls['cvv'].value);
      this.sendPayment()
    }
  }

  sendPayment(){
    this.submitted = true
    console.log("sending data payment processor")
   // console.log(JSON.stringify(this.payment))
    this.notifyService.payment(this.payment)
      .subscribe((data: any) => {
        console.log(data)

        if(data === null){
          //redirectPage("error")
        }else{

          if(data.status == 404){
            //redirectPage("error")

          }else{
            console.log(JSON.stringify(data))
            data.isGift = this.payment.isGift;
            localStorage.setItem("ppResponse", JSON.stringify(data)) 
            let callback = JSON.parse(data.result.urlCallback)
            console.log(callback)
            console.log(callback.payment_method.url)
            window.location.replace(callback.payment_method.url);
          }
          
        }

      },
        (error) => {
          console.error(JSON.stringify(error))
          //redirectPage("error")

        },
        () => {
         
        });
  }

  setValues() {
    let amount: any = parseFloat(document.getElementById('amount_span')?.innerHTML || '');
    this.payment.amount=amount;
    this.payment.cardNumber=document.getElementById('number_span')?.innerHTML || '';
    //this.payment.expiry_month=document.getElementById('expiry_month_span')?.innerHTML || '';
    //this.payment.expiry_year=document.getElementById('expiry_year_span')?.innerHTML || '';;
    this.payment.expireData = document.getElementById('expiredData_span')?.innerHTML || '';;
    this.payment.idTransaction=document.getElementById('idTransaction_span')?.innerHTML || '';;
    this.payment.idUser=document.getElementById('idUser_span')?.innerHTML || '';;
    this.payment.idBeneficiary=document.getElementById('idBeneficiary_span')?.innerHTML || '';;
    this.payment.idCard=document.getElementById('idCard_span')?.innerHTML || '';;
    this.payment.description=document.getElementById('description_span')?.innerHTML || '';;
    this.payment.comission=+document.getElementById('comission_span')?.innerHTML || 0;
    this.payment.latitud=document.getElementById('latitud_span')?.innerHTML || '';;
    this.payment.longitud=document.getElementById('longitud_span')?.innerHTML || '';;
    this.payment.cardType=document.getElementById('cardType_span')?.innerHTML || '';;
    this.payment.userName=document.getElementById('userName_span')?.innerHTML || '';;
    this.payment.beneficiaryEmail=document.getElementById('beneficiaryEmail_span')?.innerHTML || '';;
    this.payment.isForValidCard=document.getElementById('isForValidCard')?.innerHTML === "true"?true:false || false;
    this.payment.isGift= document.getElementById('isGift')?.innerHTML === "true"?true:false || false;
    this.payment.email=document.getElementById('email_span')?.innerHTML || '';;
    this.payment.userEmail=document.getElementById('userEmail_span')?.innerHTML || '';;

  }


  errors(){
    console.log(this.formGroup)
  }



}
